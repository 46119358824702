<template>
  <!-- :modules="modules" -->
  <Swiper
    :direction="direction"
    :autoplay="true"
    :resize-observer="true"
    :allow-touch-move="allowTouchMove"
    :prevent-interaction-on-transition="true"
    :auto-height="true"
    :style="{ '--swiper-navigation-color': 'white' }"
    :navigation="
      showNavigation ? { disabledClass: 'opacity-0' } : showNavigation
    "
    :slides-per-group="slidesPerView"
    :slides-per-view="slidesPerView"
    :space-between="spaceBetween"
    @swiper="setSwiper"
    @slideChange="slideChange"
  >
    <slot>
      <div />
    </slot>
  </Swiper>
</template>
<script>
// Import Swiper Vue.js components
import { ref } from 'vue'
import { Swiper } from 'swiper/vue'

// Navigation
import SwiperCore, { Navigation } from 'swiper'
SwiperCore.use([Navigation])

export default {
  name: 'Slider',
  components: {
    Swiper
  },
  props: {
    slidesPerView: {
      type: Number,
      default: 1
    },
    showNavigation: {
      type: Boolean,
      default: false
    },
    spaceBetween: {
      type: Number,
      default: 50
    },
    allowTouchMove: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: 'horizontal'
    }
  },
  emits: ['slideChange'],
  setup (props, { emit }) {
    const swiper = ref(null)

    const setSlideIndex = (index) => {
      swiper.value.slideTo(index)
    }

    const setSwiper = (obj) => {
      swiper.value = obj
    }

    const slideChange = ($event) => {
      emit('slideChange', $event.activeIndex)
    }

    return { setSlideIndex, setSwiper, slideChange }
  }
}
</script>
<style lang="scss" module></style>
